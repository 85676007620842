<template>
  <div class="anno-root">
    <div style="height: 35px"></div>
    <span class="titleTop">Message from 21-22 FBLA China National President</span>
    <video :src="'https://mainbucket.learningfirst.cn/21-22fbla/FBLA%20WELCOMING%20VIDEO.mp4'" controls="control" controlsList="nodownload" class="videoClass"></video>
    <!-- <div class="block" style="text-align:center;width:100%">
      <p>FBLA东区委员会欢迎辞</p>
      <p>FBLA Eastern Region Council Welcome</p>
    </div> -->

    <div class="anno-content">
      <div class="anno-list">
        <div v-for="(item,index) in dataList" :key="index">
          <div class="anno-list-item">
            <div class="anno-list-item-content">
              <div class="titleStyle">{{ item.title }}</div>
              <div class="contentStyle">{{ item.content }}</div>

              <!-- <div class="anno-list-item-content">
              {{ item.content }}
              </div> -->
            </div>
            
            <div class="anno-list-item-date">{{ formatCreateTime(item.create_time) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getHomeAnnouncements} from '../../api/index'
import {formatDate} from '../../utils/date'
import '../../assets/common/font.css'

export default {
  name: "index",
  data() {
    return {
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    fetchData() {
      getHomeAnnouncements().then((res) => {
        var result = res.data.data
        for (var i = 0; i < result.length; i++) {
          this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.anno-list-item-content {
  flex: 1;
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #2D50B0;
  font-size: 26px;
  // font-weight: bold;
}
.titleStyle{
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.contentStyle{
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.anno-list-item-date {
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: #2D50B0;
  font-size: 20px;
  font-weight: bold;
}

.anno-list-item {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #2D50B0;
  display: flex;
  flex-direction: row;
}

.anno-root {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anno-content {
  flex: 1;
  width: 100%;
  margin-top:20px;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.anno-list {
  padding-left: 40px;
  padding-right: 40px;
}

.videoClass{
  width:80%;
  left: 10%;
  margin-left: 10%;
  margin-top: 10px;
  align-content: center;
  align-items: center;
  
}
.titleTop{
  width:80%;
  left: 10%;
  margin-left: 10%;
  margin-top: 10px;
  align-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

</style>